<template>
  <section id="WhatWeDo">
    <div class="mobile-subnav">
      <SideBarNavMobile subsectionTitle="What We Do" />
    </div>
    <div class="whatwedo-cnt">
      <div class="venue-title-cnt">
        <div class="venue-title">What We Do</div>
        <div class="line-divider"></div>
      </div>
      <div class="items">
        <div class="item">
          <img src="@/assets/icons/WhatWeDo/icon-receptions.svg" />
          Receptions
        </div>
        <div class="item">
          <img src="@/assets/icons/WhatWeDo/diamond-icon.svg" />
          Experiential Events
        </div>
        <div class="item">
          <img src="@/assets/icons/WhatWeDo/icon-dinners.svg" />
          Dinners & Galas
        </div>
        <div class="item">
          <img src="@/assets/icons/WhatWeDo/icon-product.svg" />
          Product Launches
        </div>
        <div class="item">
          <img src="@/assets/icons/WhatWeDo/person-icon.svg" />
          Breakouts
        </div>
        <div class="item">
          <img src="@/assets/icons/WhatWeDo/icon-drives.svg" />
          Ride & Drives
        </div>
        <div class="item">
          <img src="@/assets/icons/WhatWeDo/icon-keynotes.svg" />
          Keynotes
        </div>
        <div class="item">
          <img src="@/assets/icons/WhatWeDo/icon-holiday.svg" />
          Holiday Parties
        </div>
        <div class="item">
          <img src="@/assets/icons/WhatWeDo/icon-concerts.svg" />
          Concerts
        </div>
        <div class="item">
          <img src="@/assets/icons/WhatWeDo/icon-wedding.svg" />
          Weddings & Birthdays
        </div>
      </div>
    </div>
  </section>
</template>
  
  <script>
import SideBarNavMobile from "@/components/Nav/SideBarNavMobile";

export default {
  name: "WhatWeDo",
  components: {
    SideBarNavMobile,
  },
  data() {
    return {
      items: [
        {
          icon: "icon-receptions.svg",
          title: "Receptions",
        },
        {
          icon: "diamond-icon.svg",
          title: "Experiential Events",
        },
        {
          icon: "icon-dinners.svg",
          title: "Dinners & Galas",
        },
        {
          icon: "icon-product.svg",
          title: "Product Launches",
        },
        {
          icon: "person-icon.svg",
          title: "Breakouts",
        },
        {
          icon: "icon-drives.svg",
          title: "Ride & Drives",
        },
        {
          icon: "icon-keynotes.svg",
          title: "Keynotes",
        },
        {
          icon: "icon-holiday.svg",
          title: "Holiday Parties",
        },
        {
          icon: "icon-concerts.svg",
          title: "Concerts",
        },
        {
          icon: "icon-wedding.svg",
          title: "Weddings & Birthdays",
        },
      ],
    };
  },
};
</script>
  
  <style lang="scss" scoped>
#WhatWeDo {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background: url("~@/assets/images/VenueOverview/bg-what-we-do.jpg") scroll
    no-repeat center center;
  background-size: cover;
  padding: 160px 0 20px 268px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.85) 51%,
      rgba(0, 0, 0, 0.02) 80%
    );
    pointer-events: none;
    // @media (max-width: $lg) {
    //   display: none;
    // }
  }
  @media (max-width: $lg) {
    padding: 160px 0px 50px 0px;
    height: auto;
    background: url("~@/assets/images/VenueOverview/bg-what-we-do.jpg") fixed
      no-repeat center center;
    background-size: cover;
  }
  .whatwedo-cnt {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin: 0 auto;
    padding: 0 50px;
    align-items: flex-start;
    .venue-title-cnt {
      margin-bottom: 50px;
      .venue-title {
        width: 100%;
        margin-bottom: 25px;
        font-size: 35px;
        font-family: "ArtegraSans-Bold";
        line-height: 30px;
        letter-spacing: 2.19px;
        text-transform: uppercase;
        color: #ffffff;
      }
      .line-divider {
        position: relative;
        width: 100%;
        height: 2px;
        background: #ffffff;
        box-shadow: 0 0 9px 2px #ffffff;
      }
    }
    .items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 40px 0;
      width: 100%;
      max-width: 800px;
      .item {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 29px;
        font-family: "ArtegraSans-Bold";
        font-size: 20px;
        line-height: 18px;
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
        @media (max-width: 750px) {
          width: 100%;
        }
        @media (max-width: 450px) {
          font-size: 16px;
        }
      }
    }
  }
  .mobile-subnav {
    display: none;
    @media (max-width: $lg) {
      display: block;
    }
  }
}
</style>